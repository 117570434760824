<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardBody>
            <CJumbotron
              color="pastelpurple"
              text-color="white"

            >
              <center>
                <h1 class="display-3">Troubleshooting Guide</h1>
                <p class="lead">
                    แนวทางการแก้ไขปัญหาที่พบ
                </p>
              </center>
            </CJumbotron>
                <CRow>
                    <CCol>
                        <CCard>
                            <CCardHeader>
                                <strong style="font-size:25px"><i class="far fa-lightbulb"></i>ขอเปิดคอร์สในกรณีที่ไม่สามารถเปิดผ่านระบบเองได้</strong>
                            </CCardHeader>
                            <CCardBody>
                                <CListGroup>
                                    <CListGroupItem href="https://tlic.atlassian.net/servicedesk/customer/portal/1/group/4/create/1" target="_blank" color="info">
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-1"><b>Canvas Request Course</b></h5>
                                        </div>
                                        <p class="mb-1">
                                            การขอเปิดคอร์สบน Canvas ในกรณีพิเศษที่ไม่สามารถเปิดผ่านระบบเองได้
                                        </p>
                                        <div style="position: absolute; top: 10px; right: 20px; width: 100px; text-align:right;">
                                            <i class="fas fa-chevron-right fa-4x"></i>
                                        </div>
                                    </CListGroupItem>
                                    <CListGroupItem href="https://elearning.cmu.ac.th/course/request.php" target="_blank" color="primary">
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-1"><b>KC-moodle Request Course</b></h5>
                                        </div>
                                        <p class="mb-1">
                                            การขอเปิดคอร์สบน KC-moodle ในกรณีพิเศษที่ไม่สามารถเปิดผ่านระบบเองได้
                                        </p>
                                        <div style="position: absolute; top: 10px; right: 20px; width: 100px; text-align:right;">
                                            <i class="fas fa-chevron-right fa-4x"></i>
                                        </div>
                                    </CListGroupItem>
                                    <CListGroupItem href="https://exam.cmu.ac.th/course/request.php" target="_blank" color="strongblue">
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-1"><b>Exam Request Course</b></h5>
                                        </div>
                                        <p class="mb-1">
                                            การขอเปิดคอร์สบน Exam Online เพื่อใช้ในการคุมสอบ
                                        </p>
                                        <div style="position: absolute; top: 10px; right: 20px; width: 100px; text-align:right;">
                                            <i class="fas fa-chevron-right fa-4x"></i>
                                        </div>
                                    </CListGroupItem>
                                </CListGroup>
                            </CCardBody>
                        </CCard>
                        <CCard>
                            <CCardHeader>
                                <strong style="font-size:25px"><i class="far fa-question-circle"></i>สาเหตุที่มักทำให้ไม่พบกระบวนวิชาที่ต้องการ</strong>
                            </CCardHeader>
                            <CCardBody>
                                <CListGroup>
                                    <CListGroupItem>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-1"><b>1. ชื่อผู้สอนในสำนักทะเบียนยังระบุเป็น "คณาจารย์"</b></h5>
                                        </div>
                                        <p class="mb-1">
                                            วิธีแก้ไขคือ ขอให้คณะหรือภาควิชาแจ้งชื่อผู้สอนให้กับสำนักทะเบียน 
                                        </p>
                                    </CListGroupItem>
                                    <CListGroupItem>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-1"><b>2. ท่านไม่ใช่ผู้รับผิดชอบหลักของกระบวนวิชา</b></h5>
                                        </div>
                                        <p class="mb-1">
                                            เฉพาะผู้รับผิดชอบหลักเท่านั้นที่สามารถสร้างคอร์สได้ โดยระบบจะยึดข้อมูลจากสำนักทะเบียนเป็นหลัก
                                        </p>
                                    </CListGroupItem>
                                    <CListGroupItem>
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-1"><b>3. กระบวนวิชาที่ต้องการเปิดเป็นกระบวนวิชารายปี (Yearly)</b></h5>
                                        </div>
                                        <p class="mb-1">
                                            กระบวนวิชารายปีจะมีข้อมูลในสำนักทะเบียนในเทอมที่ 1 ของปีการศึกษาเท่านั้น
                                        </p>
                                    </CListGroupItem>
                                </CListGroup>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'Guide'
}
</script>
